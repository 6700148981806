import { useForm, ValidationError } from "@formspree/react";
import React, { Fragment, useState } from "react";

const Contact: React.FC = () => {
  const [submit, handleSubmit] = useForm("xbjqlvkd");

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  return (
    <Fragment>
      <div id="contact" className="flex flex-col items-center justify-center mt-12 lg:mt-24">
        <h1 className="font-extrabold text-5xl text-white mb-5">Contact Us.</h1>
        <div className=" relative">
          <form onSubmit={handleSubmit}>
            <div className="pb-3">
              <label
                htmlFor="email"
                className="text-green-300 font-bold text-lg"
              >
                Email
                <span className="text-red-500 required-dot">*</span>
              </label>
            </div>
            <input
              type="email"
              id="email"
              className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-green-400 focus:border-transparent"
              name="email"
              placeholder="Your email"
              value={submit.succeeded ? "" : email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <ValidationError
              prefix="Email"
              field="email"
              errors={submit.errors}
            />
            <div className="pt-5">
              <label className="text-gray-700" htmlFor="message">
                <textarea
                  className="flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-green-400 focus:border-transparent"
                  id="message"
                  placeholder="Enter your comment"
                  name="message"
                  rows={5}
                  cols={80}
                  value={submit.succeeded ? "" : message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                ></textarea>
                <ValidationError
                  prefix="Message"
                  field="message"
                  errors={submit.errors}
                />
              </label>
            </div>
            <div>
              <button
                type="submit"
                className="py-2 px-4 mt-2 bg-green-500 hover:bg-green-600 focus:ring-green-500 focus:ring-offset-green-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                disabled={submit.submitting}
              >
                {submit.succeeded ? "Sent!" : "Send"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default Contact;

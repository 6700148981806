import React, { Fragment } from "react";

const Footer: React.FC = () => {
  return (
    <Fragment>
      <footer className="pt-32">
        <div className="flex flex-row items-center justify-center py-7 gap-6">
          <a
            href="https://twitter.com/indytechbox?lang=en"
            target="_blank"
            rel="noreferrer"
          >
            <span className="text-gray-500 hover:text-white">Twitter</span>
          </a>
          <a
            href="https://www.facebook.com/indytechbox/"
            target="_blank"
            rel="noreferrer"
          >
            <span className="text-gray-500 hover:text-white">Facebook</span>
          </a>
        </div>
        <p className="text-gray-400 text-center">
          © {new Date().getFullYear()} Indy Tech Box. All rights reserved.
        </p>
      </footer>
    </Fragment>
  );
};

export default Footer;

import React, { Fragment } from "react";
import { servicesData } from "../servicesData/data";

const Services: React.FC = () => {
  return (
    <Fragment>
      <div className="flex flex-col items-center justify-center lg:mt-12 mx-auto">
        <h1 className="text-green-400 dark:text-white text-5xl font-extrabold">
          Services
        </h1>
      
        <div className="flex flex-col gap-y-6 lg:grid lg:grid-cols-3 lg:w-4/5 lg:gap-x-6 justify-items-center mt-10">
          {servicesData.map((service) => (
            <>
              <div>
                <h4 className="text-green-300 text-xl font-bold">
                  {service.serviceName}
                </h4>
                <p className="text-gray-400 dark:text-white">
                  {service.serviceDetail}
                </p>
              </div>
            </>
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default Services;

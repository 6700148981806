export const servicesData = [
    {
        'serviceName': 'Help Desk as a Service (HAAS)',
        'serviceDetail': 'At Indy Tech Box, we offer Helpdesk as a Service(HAAS), so you as a business can relieve some stress and breathe easy knowing a team of experts is in your corner!'
    },
    {
        'serviceName': 'Software Development Services',
        'serviceDetail': 'We offer an end-to-end software development package. We see projects from start to finish and offer continuous support after the software is done!'
    },
    {
        'serviceName': 'Salesforce Support as a Service',
        'serviceDetail': 'Salesforce Support as a Service(SSaS) is something we came up with to support the clients that already use Salesforce as their existing CRM platform. We offer development support and administration support. Contact us for more details about SSaS.'
    }
]
import React, { Fragment } from "react";
import HeroSvg from "./HeroSvg";
import Typewriter from "typewriter-effect";

const Hero: React.FC = () => {
  return (
    <Fragment>
      <div className="flex flex-col items-center justify-center xl:grid xl:grid-cols-2 xl:gap-8">
        <div className="pt-12 lg:py-24">
          <div className="py-10 flex flex-col">
            <span className="dark:text-white text-gray-700 text-6xl tracking-tight font-extrabold">
              Tech Consulting
            </span>
            <span className="text-green-300 text-6xl tracking-tight font-extrabold">
              <Typewriter
                onInit={(typewriter) => {
                  typewriter.typeString("Done Right.").start();
                }}
              />
            </span>
            <p className="text-gray-400 dark:text-gray-50 text-lg py-5 w-full">
              Indy Tech Box is a full service technology consulting firm. We
              specialize in end-to-end customer solutions. Our rigorous,
              repeatable process keeps projects on track and you informed.
            </p>
            <div className="py-10">
              <a
                href="https://calendly.com/indytechbox/consulting-meeting"
                target="_blank"
                rel="noreferrer"
                className="py-5 px-6 rounded-full shadow bg-green-500 text-white text-lg font-bold hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-300 focus:ring-offset-gray-900"
              >
                <span>Schedule a Discovery Meeting Now!</span>
              </a>
            </div>
          </div>
        </div>
        <div>
          <div className="hidden xl:flex items-center justify-center lg:mx-auto xl:py-24">
            <HeroSvg />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Hero;
